import React, { ReactText, useContext, useMemo, useState } from "react"
import styled from "styled-components/macro"
import { palette, fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast/toast"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import LinkIcon from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Link"
import StyledButton from "@cbs-sports/sports-shared-client/build/cjs/components/Button/Button.styles"
import BracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Bracket"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import PoolDataContext from "../../../../Contexts/PoolDataContext"
import AnalyticScreen from "../../../../components/AnalyticsScreen"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import Analytics from "../../../../utils/analytics"
import { copyToClipboard, getInvitationUrl } from "../../../../utils/url-utils"

export const CreatePoolRoadBlockWrap = styled.div`
  will-change: scroll-position;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  padding: 2.5rem 1rem;
  background-color: ${palette.white};
  background-image: url(/static/sport-assets/basketball/games-block-road-bkg.svg);
  background-repeat: repeat-x;

  & > div.logo {
    display: block;
    position: relative;
    height: 7.5rem;
    width: 7.5rem;
    margin: 0 auto;

    svg {
      height: auto;
      max-width: 100%;
      opacity: 1;
      transition: opacity 0.5s ease-in 0.5s;
      vertical-align: top;
      margin-bottom: 0;
    }
  }

  & > .congrats-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    gap: 0.25rem;

    & > span {
      font-family: ${fontFamily.base};
      font-size: 1rem;
      font-style: normal;
      text-align: center;
      color: ${palette.gray20};
    }

    & > .congrats-text-title {
      font-weight: 700;
      line-height: 120%;
    }
    & > .congrats-text-subtitle {
      font-weight: 400;
      line-height: 130%;
    }
  }

  & > .invite__actions-box {
    margin-bottom: 2.5rem;
  }
`

const CreatePoolRoadBlock = () => {
  const poolData = useContext(PoolDataContext)
  const { deviceType, poolDetail, entryId, gameInstanceUid } = poolData || emptyObject
  const [copyToClipboardToast, setCopyToClipboardToast] = useState<ReactText>("")

  const isWomenBracket = isNCAAWTournamentMatcher.test(gameInstanceUid)

  const url = useMemo(() => {
    if (isWomenBracket) {
      return "https://cbs-sports.app.link/sYqbRH6PtFb"
    }
    return "https://cbs-sports.app.link/KwbmEC3PtFb"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWomenBracket])

  const handleCopy = (args: any) => {
    Analytics.trackAction("brackets welcome", "sharing", "copy")
    const value = getInvitationUrl(args)
    copyToClipboard(value)
    if (!copyToClipboardToast) {
      const toastId = toast.snackbar("Pool invite link copied to clipboard", {
        position: "bottom-center",
        onClose: () => {
          setCopyToClipboardToast("")
        },
      })
      setCopyToClipboardToast(toastId)
    }
  }

  return (
    <CreatePoolRoadBlockWrap>
      <AnalyticScreen feature="brackets" subfeature={`road-block`} title={`Road Block`} deviceType={deviceType} />
      <div className="logo">
        <BracketSvg />
      </div>
      <div className="congrats-text">
        <span className="congrats-text-title">Success!</span>
        <span className="congrats-text-subtitle">
          Your pool has been created with our most popular settings! Visit the CBS Sports App to customize pool settings. You can also invite players
          now using the link below.
        </span>
      </div>
      <div className="invite__actions-box">
        <Button
          variant="secondary"
          // withoutText
          type="button"
          onClick={() => handleCopy({ medium: "copy", pool: poolDetail, entryId, hasManagerRole: true })}
          data-cy="copy-link"
        >
          Invite Friends
          <ListItemIcon style={{ marginLeft: "0.25rem" }}>
            <LinkIcon />
          </ListItemIcon>
        </Button>
      </div>

      <StyledButton className="is-full-width--true variant--primary open-app-btn" as="a" href={url}>
        Open The App
      </StyledButton>
    </CreatePoolRoadBlockWrap>
  )
}

export default CreatePoolRoadBlock
